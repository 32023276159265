import React from "react";
import { alpha } from '@mui/material/styles';
import Button from "@mui/material/Button";
import config from "../../services/config";

const PrimaryButton = (props) => {
    const {children , disabled, ...other } = props;

    const primaryColor = config.getPrimaryColor();
    const primaryColorText = config.getPrimaryColorText();

    return (
        <Button
            style={{
                backgroundColor: disabled ? alpha(primaryColor, 0.20) : primaryColor,
                color:  disabled ? alpha(primaryColorText, 0.50): primaryColorText,
            }}
            disabled={disabled}
            {...other}
        >
            {children}
        </Button>
    );
};

export default PrimaryButton;
