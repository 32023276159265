import apiServer from "./api-server";

/**
 * Returns the details of a specific yubikey
 *
 * @returns {Promise<object>}
 */
function getYubikeyOTPs() {
    return new Promise((resolve, reject) => {
        return apiServer.readYubikeyOTPs().then(
            (response) => {
                return resolve(response.data.yubikey_otps);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_80fcda4d-51a8-422e-b733-9c14c7c2a951");
                reject(response);
            }
        );
    });
}

/**
 *
 * update a yubikey
 *
 * @param {string} title
 * @param {string} yubikeyOTP
 *
 * @returns {Promise<unknown>}
 */
function createYubikeyOTP(title, yubikeyOTP) {
    return new Promise((resolve, reject) => {
        return apiServer.createYubikeyOTP(title, yubikeyOTP).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * deletes a yubikey
 *
 * @param {string} yubikeyOTPId
 *
 * @returns {Promise<unknown>}
 */
function deleteYubikeyOTP(yubikeyOTPId) {
    return new Promise((resolve, reject) => {
        return apiServer.deleteYubikeyOTP(yubikeyOTPId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * deletes a yubikey
 *
 * @param {string} yubikeyOTPId
 * @param {string} title
 * @param {string} yubikeyOTPToken
 *
 * @returns {Promise<unknown>}
 */
function verifyYubikeyOTP(yubikeyOTPId, title, yubikeyOTPToken) {
    return new Promise((resolve, reject) => {
        return apiServer.verifyYubikeyOTP(yubikeyOTPId, title, yubikeyOTPToken).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

const service = {
    getYubikeyOTPs,
    deleteYubikeyOTP,
    createYubikeyOTP,
    verifyYubikeyOTP,
};

export default service;
