import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import config from "../services/config";

const Copyright = (props) => {
    const { t } = useTranslation();
    const legalNoticeUrl = config.getLegalNoticeUrl();
    const privacyPolicyUrl = config.getPrivacyPolicyUrl();
    return (
        <>
            {(legalNoticeUrl || privacyPolicyUrl) && (
                <Typography variant="body2" color="textSecondary" align="center" style={{ marginBottom: "10px" }}>
                    {legalNoticeUrl && (
                        <Link color="inherit" href={legalNoticeUrl} style={{ marginRight: "5px" }}>
                            {t("LEGAL_NOTICE")}
                        </Link>
                    )}
                    {privacyPolicyUrl && (
                        <Link color="inherit" href={privacyPolicyUrl} style={{ marginRight: "5px" }}>
                            {t("PRIVACY_POLICY")}
                        </Link>
                    )}
                </Typography>
            )}

            <Typography variant="body2" color="textSecondary" align="center">
                {t("PROTECTED_BY")}{" "}
                <Link color="inherit" href="https://qeyId.com/">
                    QeyID
                </Link>
            </Typography>
        </>
    );
}

export default Copyright;
