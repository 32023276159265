/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from "prop-types";
import countryService from "../services/country";

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
}));

const CountrySelectField = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [countries, setCountries] = useState([]);
    const { fullWidth, variant, margin, helperText, error, required, onChange, value, className } = props;

    React.useEffect( () => {
        countryService.getCountries().then(
            (countries) => {
                setCountries(Object.keys(countries).map((code) => {
                    return { code: code, label: countries[code] };
                }))
            },
            (response) => {
                console.log(response);
            }
        );
    }, []);


    let defaulValue = null;
    if (value && countries && countries.length) {
        defaulValue = countries.find(function (country) {
            return country.code === value;
        });
    }

    return (
        <Autocomplete
            options={countries}
            classes={{
                option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => {
                return option ? option.label : "";
            }}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    {option.label} ({option.code})
                </li>
            )}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            isOptionEqualToValue={(option, value) => {
                return option.code === value.code;
            }}
            value={defaulValue}
            renderInput={(params) => (
                <TextField
                    className={className}
                    {...params}
                    label={t("COUNTRY")}
                    required={required}
                    margin={margin}
                    variant={variant}
                    helperText={helperText}
                    error={error}
                    fullWidth={fullWidth}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}

CountrySelectField.defaultProps = {
    error: false,
};

CountrySelectField.propTypes = {
    value: PropTypes.string,
    fullWidth: PropTypes.bool,
    error: PropTypes.bool,
    required: PropTypes.bool,
    helperText: PropTypes.string,
    variant: PropTypes.string,
    margin: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
};

export default CountrySelectField;
