import apiServer from "./api-server";

/**
 * Initiates a signin. Tests whether a provided user exists and will return the authentication methods
 *
 * @param email
 * @returns {Promise<object>}
 */
function signinInit(email) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const next = urlSearchParams.get('next') || undefined;
    return new Promise((resolve, reject) => {
        return apiServer.signinInit(email, next).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 * Triggers the actual singin to log the user in.
 *
 * @param email
 * @param password
 * @returns {Promise<object>}
 */
function signin(email, password) {
    return new Promise((resolve, reject) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const next = urlSearchParams.get('next') || undefined;
        return apiServer.signin(email, password, next).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 * Triggers the actual signup to register a new user
 *
 * @param email
 * @param password
 * @returns {Promise<object>}
 */
function signup(email, password) {
    return new Promise((resolve, reject) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const next = urlSearchParams.get('next') || undefined;
        return apiServer.signup(email, password, next).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 * Returns the details of a specific psono saas instance
 *
 * @returns {Promise<object>}
 */
function getUser() {
    return new Promise((resolve, reject) => {
        return apiServer.readUser().then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_e34530e6-563c-4594-8e24-5f5f67f37939");
                reject(response);
            }
        );
    });
}

/**
 *
 * update a psono saas instance
 *
 * @param {string} email
 * @param {string} familyName
 * @param {string} givenName
 * @param {string} middleName
 * @param {string} nickname
 * @param {string} preferredUsername
 * @param {string} website
 * @param {string} birthdate
 * @param {string} phoneNumber
 * @param {string} zoneinfo
 * @param {string} addressFormatted
 * @param {string} addressStreetAddress
 * @param {string} addressLocality
 * @param {string} addressRegion
 * @param {string} postalCode
 * @param {string} country
 *
 * @returns {Promise<unknown>}
 */
function updateUser(
    email,
    familyName,
    givenName,
    middleName,
    nickname,
    preferredUsername,
    website,
    birthdate,
    phoneNumber,
    zoneinfo,
    addressFormatted,
    addressStreetAddress,
    addressLocality,
    addressRegion,
    postalCode,
    country
) {
    return new Promise((resolve, reject) => {
        return apiServer
            .updateUser(
                email,
                familyName,
                givenName,
                middleName,
                nickname,
                preferredUsername,
                website,
                birthdate,
                phoneNumber,
                zoneinfo,
                addressFormatted,
                addressStreetAddress,
                addressLocality,
                addressRegion,
                postalCode,
                country
            )
            .then(
                (response) => {
                    return resolve(response.data);
                },
                (response) => {
                    reject(response.data);
                }
            );
    });
}

/**
 *
 * deletes a psono saas instance
 *
 * @returns {Promise<unknown>}
 */
function deleteUser() {
    return new Promise((resolve, reject) => {
        return apiServer.deleteUser().then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                for (let i = 0; i < response.data.length; i++) {
                    response.data[i] = {'message': response.data[i]}
                }
                reject(response.data);
            }
        );
    });
}

const service = {
    signinInit,
    signin,
    signup,
    getUser,
    deleteUser,
    updateUser,
};

export default service;
