import apiServer from "./api-server";

let cachedCountries;

/**
 * GET: Returns all countries
 *
 * @returns {Promise<Array>}
 */
function getCountries() {
    return new Promise((resolve, reject) => {
        if (cachedCountries && cachedCountries.length) {
            return resolve(cachedCountries);
        } else {
            return apiServer.readCountries().then(
                (response) => {
                    cachedCountries = response.data.countries;
                    return resolve(cachedCountries);
                },
                (response) => {
                    console.log("UNEXPECTED_ERROR_6338e29a-dd7d-4683-ae3d-1f5396084c88");
                    reject(response);
                }
            );
        }
    });
}

const service = {
    getCountries,
};

export default service;
