import React from "react";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from '@mui/material/Alert';
import { Link, useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";

import config from "../services/config";

import Logo from "../components/logo";
import CSRFToken from "../components/csrftoken";
import Copyright from "../components/copyright";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const PasswordResetCompleteView = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const location = useLocation();
    const errors = config.getErrors();

    return (
        <Container component="main" maxWidth="xs">
            <Helmet>
                <title>{t("PASSWORD_RESET_COMPLETE")} | powered by QeyID</title>
            </Helmet>
            <CssBaseline />
            <div className={classes.paper}>
                <Logo />
                <Typography component="h1" variant="h5">
                    {t("PASSWORD_RESET_COMPLETE")}
                </Typography>
                <Alert severity="success" style={{ marginTop: "10px" }}>
                    {t("PASSWORD_RESET_COMPLETE_INFO")}
                </Alert>
                <form className={classes.form} noValidate method="post">
                    <CSRFToken />
                    {errors && "__all__" in errors && (
                        <Alert severity="error" style={{ marginBottom: "10px" }}>
                            {t(errors["__all__"][0]["message"])}
                        </Alert>
                    )}
                    <p style={{ marginTop: "10px", textAlign: "center" }}>
                        <Link to={{ pathname: "/signin/", search: location.search }}>{t("BACK")}</Link>
                    </p>
                </form>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default PasswordResetCompleteView;
