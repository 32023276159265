import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import yubikeyOTPService from "../../services/yubikey-otp";
import Table from "../../components/table";


const YubikeyOTPListTable = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [yubikeyOTPs, setYubikeyOTPs] = useState([]);

    React.useEffect( () => {
        loadYubikeyOTPs()
    }, []);

    const loadYubikeyOTPs = () => {
        yubikeyOTPService.getYubikeyOTPs().then(
            (yubikeyOTPs) => {
                setYubikeyOTPs(yubikeyOTPs.map((yubikeyOTP) => [yubikeyOTP.id, yubikeyOTP.title]))
            },
            (response) => {
                console.log(response);
            }
        );
    }

    const onCreate = () => {
        history.push("/account/yubikey-otp/create/");
    };
    const onDelete = (rowData) => {
        yubikeyOTPService.deleteYubikeyOTP(rowData[0]).then(
            () => {
                loadYubikeyOTPs();
            },
            (response) => {
                console.log(response);
            }
        );
    };

    const columns = [{ name: t("ID"), options: { display: "false" } }, { name: t("TITLE") }];

    const options = {
        filterType: "checkbox",
    };

    return <Table data={yubikeyOTPs} columns={columns} options={options} onCreate={onCreate} onDelete={onDelete} />;
}

export default YubikeyOTPListTable;
