import React from "react";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Alert from '@mui/material/Alert';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {Helmet} from "react-helmet";

import config from "../services/config";

import Logo from "../components/logo";
import CSRFToken from "../components/csrftoken";
import Copyright from "../components/copyright";
import PrimaryButton from "../components/button/primary-button";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    ul: {
        paddingLeft: theme.spacing(2),
    },
    hidden: {
        display: "none",
    },
}));

const AuthorizeView = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const requireVerifiedEmail = config.getRequireVerifiedEmail();
    const scopes = config.getScopes();
    const userIsEmailVerified = config.getUserIsEmailVerified();
    const paramClientId = config.getParamClientId();
    const paramRedirectUri = config.getParamRedirectUri();
    const paramResponseType = config.getParamResponseType();
    const paramScope = config.getParamScope();
    const paramState = config.getParamState();
    const paramNonce = config.getParamNonce();
    const paramCodeChallenge = config.getParamCodeChallenge();
    const paramCodeChallengeMethod = config.getParamCodeChallengeMethod();

    return (
        <Container component="main" maxWidth="xs">
            <Helmet>
                <title>{t("AUTHORIZE")} | powered by QeyID</title>
            </Helmet>
            <CssBaseline />
            <div className={classes.paper}>
                <Logo />
                <Typography component="h1" variant="h5">
                    {t("AUTHORIZE")}
                </Typography>
                <form className={classes.form} noValidate method="post" action="/authorize/">
                    {requireVerifiedEmail && !userIsEmailVerified ? (
                        <Alert severity="info">{t("APPLICATION_REQUIRES_A_VERIFIED_EMAIL")}</Alert>
                    ) : (
                        <>
                            <CSRFToken />
                            <Alert severity="info">
                                {t("APPLICATION_IS_REQUESTING_THE_FOLLOWING_RIGHTS")}
                                <br />
                                <ul className={classes.ul}>
                                    {scopes.map((scope) => (
                                        <li key={scope.name}>
                                            {t(scope.name)}: {t(scope.description)}
                                        </li>
                                    ))}
                                </ul>
                            </Alert>
                            {paramClientId && <TextField name="client_id" className={classes.hidden} defaultValue={paramClientId} />}
                            {paramRedirectUri && <TextField name="redirect_uri" className={classes.hidden} defaultValue={paramRedirectUri} />}
                            {paramResponseType && <TextField name="response_type" className={classes.hidden} defaultValue={paramResponseType} />}
                            {paramScope && <TextField name="scope" className={classes.hidden} defaultValue={paramScope} />}
                            {paramState && <TextField name="state" className={classes.hidden} defaultValue={paramState} />}
                            {paramNonce && <TextField name="nonce" className={classes.hidden} defaultValue={paramNonce} />}
                            {paramCodeChallenge && <TextField name="code_challenge" className={classes.hidden} defaultValue={paramCodeChallenge} />}
                            {paramCodeChallengeMethod && (
                                <TextField name="code_challenge_method" className={classes.hidden} defaultValue={paramCodeChallengeMethod} />
                            )}
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <PrimaryButton
                                        type="submit"
                                        name="allow"
                                        value="Authorize"
                                        fullWidth
                                        variant="contained"
                                        className={classes.submit}
                                    >
                                        {t("AUTHORIZE")}
                                    </PrimaryButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button type="submit" fullWidth style={{ color: "inherit" }} className={classes.submit}>
                                        {t("DECLINE")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </form>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default AuthorizeView;
