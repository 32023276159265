import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from '@mui/material/Alert';
import Grid from "@mui/material/Grid";

import config from "../services/config";

import Logo from "../components/logo";
import CSRFToken from "../components/csrftoken";
import Copyright from "../components/copyright";
import PrimaryButton from "../components/button/primary-button";
import helperService from "../services/helper";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const PasswordResetConfirmView = (props) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const passwordResetForm = useRef(null);
    const [errors, setErrors] = useState(config.getErrors());
    const [newPassword1, setNewPassword1] = useState("");
    const [newPassword2, setNewPassword2] = useState("");

    const onPasswordReset = () => {
        const minSpecialCharacterGroups = config.minSpecialCharacterGroups();
        const minPasswordLength = config.getMinPasswordLength();

        if (newPassword1 !== newPassword2) {
            setErrors({
                new_password2: [{ message: "PASSWORDS_DIDNT_MATCH" }],
            });
        } else if (newPassword1.length < minPasswordLength) {
            setErrors({
                new_password1: [{ message: "PASSWORD_TOO_SHORT" }],
            });
        } else {
            let complexity = 0;
            if (helperService.hasNumber(newPassword1)) {
                complexity = complexity + 1;
            }
            if (helperService.hasUppercaseLetter(newPassword1)) {
                complexity = complexity + 1;
            }
            if (helperService.hasLowercaseLetter(newPassword1)) {
                complexity = complexity + 1;
            }
            if (helperService.hasSpecialCharacter(newPassword1)) {
                complexity = complexity + 1;
            }
            if (complexity < minSpecialCharacterGroups) {
                setErrors({
                    new_password1: [{ message: "PASSWORD_NOT_COMPLEX_ENOUGH" }],
                });
            } else {
                passwordResetForm.current.submit();
            }
        }
    };

    const abort = () => {
        window.location.href = '/'
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Logo />
                <Typography component="h1" variant="h5">
                    {t("PASSWORD_RESET")}
                </Typography>
                <form className={classes.form} noValidate method="post" ref={passwordResetForm}>
                    <CSRFToken />
                    <TextField
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        name="new_password1"
                        label={t("NEW_PASSWORD")}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        error={errors && "new_password1" in errors}
                        helperText={errors && "new_password1" in errors ? t(errors["new_password1"][0]["message"]) : ""}
                        value={newPassword1}
                        onChange={(event) => {
                            setNewPassword1(event.target.value);
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        name="new_password2"
                        label={t("NEW_PASSWORD_REPEAT")}
                        type="password"
                        id="new_password2"
                        error={errors && "new_password2" in errors}
                        helperText={errors && "new_password2" in errors ? t(errors["new_password2"][0]["message"]) : ""}
                        value={newPassword2}
                        onChange={(event) => {
                            setNewPassword2(event.target.value);
                        }}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                onClick={abort}
                                fullWidth
                                style={{ color: "inherit" }}
                                className={classes.submit}
                            >
                                {t("ABORT")}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <PrimaryButton
                                fullWidth
                                variant="contained"
                                disabled={!newPassword1 || !newPassword2 || newPassword1 !== newPassword2}
                                className={classes.submit}
                                onClick={onPasswordReset}
                            >
                                {t("SEND")}
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                    {errors && "__all__" in errors && (
                        <Alert severity="error" style={{ marginBottom: "10px" }}>
                            {t(errors["__all__"][0]["message"])}
                        </Alert>
                    )}
                </form>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default PasswordResetConfirmView;
