import axios from "axios";
import config from "./config";

function getServerUrlPrefix() {
    return "/api/v1";
}

/**
 * Helper function that handled the actual requests and encrypts them and decrypts the result (if applicable)
 *
 * @param {function} method
 * @param {string} endpoint
 * @param {object} data
 * @param {object} headers
 * @param {string} session_secret_key
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function call(method, endpoint, data, headers) {
    const url = getServerUrlPrefix() + endpoint;

    headers["X-Requested-With"] = "XMLHttpRequest";
    headers["X-CSRFTOKEN"] = config.getCSRFToken();

    return new Promise((resolve, reject) => {
        axios({
            method,
            url,
            data,
            headers,
        })
            .then((data) => {
                resolve(data);
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        // OIDC ID token expired, lets refresh
                        // expiration interval can be changed with OIDC_RENEW_ID_TOKEN_EXPIRY_SECONDS
                        location.reload();
                    }
                    console.log(error.response);
                    return reject(error.response);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                }
                console.log(error.config);
                reject({ errors: ["SERVER_OFFLINE"] });
            });
    });
}

/**
 * GET: Returns projects
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readCountries() {
    const endpoint = "/country/";
    const connectionType = "GET";
    const data = null;
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns states
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readStates() {
    const endpoint = "/state/";
    const connectionType = "GET";
    const data = null;
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * POST: Initiates a signin. Tests whether a provided user exists and will return the authentication methods
 *
 * @param {string} email
 * @param {string} next
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function signinInit(
    email,
    next,
) {
    const endpoint = "/me/signin-init/";
    const connectionType = "POST";
    const data = {
        email: email,
        next: next,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * POST: Logs the user in by sending the username and password to the userver
 *
 * @param {string} email
 * @param {string} password
 * @param {string} next
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function signin(
    email,
    password,
    next,
) {
    const endpoint = "/me/signin/";
    const connectionType = "POST";
    const data = {
        email: email,
        password: password,
        next: next,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * POST: Registers a new user
 *
 * @param {string} email
 * @param {string} password
 * @param {string} next
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function signup(
    email,
    password,
    next,
) {
    const endpoint = "/me/signup/";
    const connectionType = "POST";
    const data = {
        email: email,
        password: password,
        next: next,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns user info
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readUser() {
    const endpoint = "/me/";
    const connectionType = "GET";
    const data = null;
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * PUT: Updates a user info
 *
 * @param {string} email
 * @param {string} familyName
 * @param {string} givenName
 * @param {string} middleName
 * @param {string} nickname
 * @param {string} preferredUsername
 * @param {string} website
 * @param {string} birthdate
 * @param {string} phoneNumber
 * @param {string} zoneinfo
 * @param {string} addressFormatted
 * @param {string} addressStreetAddress
 * @param {string} addressLocality
 * @param {string} addressRegion
 * @param {string} postalCode
 * @param {string} country
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function updateUser(
    email,
    familyName,
    givenName,
    middleName,
    nickname,
    preferredUsername,
    website,
    birthdate,
    phoneNumber,
    zoneinfo,
    addressFormatted,
    addressStreetAddress,
    addressLocality,
    addressRegion,
    postalCode,
    country
) {
    const endpoint = "/me/";
    const connectionType = "PUT";
    const data = {
        email: email,
        family_name: familyName,
        given_name: givenName,
        middle_name: middleName,
        nickname: nickname,
        preferred_username: preferredUsername,
        website: website,
        birthdate: birthdate,
        phone_number: phoneNumber,
        zoneinfo: zoneinfo,
        address_formatted: addressFormatted,
        address_street_address: addressStreetAddress,
        address_locality: addressLocality,
        address_region: addressRegion,
        postal_code: postalCode,
        country: country,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * POST: Creates a user info
 *
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function deleteUser() {
    const endpoint = "/me/";
    const connectionType = "DELETE";
    const data = {};
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns all google authenticators
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readGoogleAuthenticators() {
    const endpoint = "/me/google-authentcator/";
    const connectionType = "GET";
    const data = null;
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * DELETE: Deletes a google authenticator
 *
 * @param googleAuthenticatorId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function deleteGoogleAuthenticator(googleAuthenticatorId) {
    const endpoint = "/me/google-authentcator/";
    const connectionType = "DELETE";
    const data = {
        google_authenticator_id: googleAuthenticatorId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * Post: Creates a google authenticator
 *
 * @param title
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function createGoogleAuthenticator(title) {
    const endpoint = "/me/google-authentcator/";
    const connectionType = "POST";
    const data = {
        title: title,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * PUT: Verifies a google authenticator and activate it
 *
 * @param googleAuthenticatorId
 * @param title
 * @param yubikeyOTPToken
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function verifyGoogleAuthenticator(googleAuthenticatorId, title, googleAuthenticatorToken) {
    const endpoint = "/me/google-authentcator/";
    const connectionType = "PUT";
    const data = {
        google_authenticator_id: googleAuthenticatorId,
        title: title,
        google_authenticator_token: googleAuthenticatorToken,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns all webauthns
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readWebauthns() {
    const endpoint = "/me/webauthn/";
    const connectionType = "GET";
    const data = null;
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * DELETE: Deletes a webauthn
 *
 * @param yubikeyOTPId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function deleteWebauthn(yubikeyOTPId) {
    const endpoint = "/me/webauthn/";
    const connectionType = "DELETE";
    const data = {
        webauthn_id: yubikeyOTPId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * Post: Creates a webauthn
 *
 * @param title
 * @param origin
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function createWebauthn(title, origin) {
    const endpoint = "/me/webauthn/";
    const connectionType = "POST";
    const data = {
        title: title,
        origin: origin,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * PUT: Verifies a webauthn and activate it
 *
 * @param {uuid} webauthnId The webauthn id
 * @param {string} credential The credentials passed by the browser
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function activateWebauthn(webauthnId, credential) {
    const endpoint = "/me/webauthn/";
    const connectionType = "PUT";
    const data = {
        webauthn_id: webauthnId,
        credential: credential,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns all yubikeys
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readYubikeyOTPs() {
    const endpoint = "/me/yubikey-otp/";
    const connectionType = "GET";
    const data = null;
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * DELETE: Deletes a yubikey
 *
 * @param yubikeyOTPId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function deleteYubikeyOTP(yubikeyOTPId) {
    const endpoint = "/me/yubikey-otp/";
    const connectionType = "DELETE";
    const data = {
        yubikey_otp_id: yubikeyOTPId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * Post: Creates a yubikey
 *
 * @param title
 * @param yubikeyOTP
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function createYubikeyOTP(title, yubikeyOTP) {
    const endpoint = "/me/yubikey-otp/";
    const connectionType = "POST";
    const data = {
        title: title,
        yubikey_otp: yubikeyOTP,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * PUT: Verifies a yubikey and activate it
 *
 * @param yubikeyOTPId
 * @param title
 * @param yubikeyOTPToken
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function verifyYubikeyOTP(yubikeyOTPId, title, yubikeyOTPToken) {
    const endpoint = "/me/yubikey-otp/";
    const connectionType = "PUT";
    const data = {
        yubikey_otp_id: yubikeyOTPId,
        title: title,
        yubikey_otp_token: yubikeyOTPToken,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * Post: Creates new backup codes
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function createBackupCodes() {
    const endpoint = "/me/backup-code/";
    const connectionType = "POST";
    const data = {};
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

const service = {
    readCountries,
    readStates,
    signinInit,
    signin,
    signup,
    readUser,
    updateUser,
    deleteUser,
    readGoogleAuthenticators,
    deleteGoogleAuthenticator,
    createGoogleAuthenticator,
    verifyGoogleAuthenticator,
    readYubikeyOTPs,
    deleteYubikeyOTP,
    createYubikeyOTP,
    verifyYubikeyOTP,
    createBackupCodes,
    readWebauthns,
    deleteWebauthn,
    createWebauthn,
    activateWebauthn,
};

export default service;
