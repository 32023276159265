import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import AccountOverviewProfile from "./overview_profile";
import AccountOverviewTwoFactor from "./overview_two_factor";
import AccountOverviewBackupCodes from "./overview_backup_codes";

import Logo from "../../components/logo";
import Copyright from "../../components/copyright";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box p={2}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    content: {
        width: "100%",
        marginTop: theme.spacing(4),
    },
}));

const AccountOverview = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [value, setValue] = useState(0);

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <Logo />
                <Typography component="h1" variant="h5">
                    {t("USER_ACCOUNT")}
                </Typography>
                <Paper square elevation={0} className={classes.content}>
                    <Tabs
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="disabled tabs example"
                    >
                        <Tab label={t("PROFILE")} />
                        <Tab label={t("TWO_FACTOR")} />
                        <Tab label={t("BACKUP_CODES")} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <AccountOverviewProfile />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <AccountOverviewTwoFactor />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <AccountOverviewBackupCodes />
                    </TabPanel>
                </Paper>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default AccountOverview;
