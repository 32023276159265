import React from "react";

import { Route, Switch } from "react-router-dom";
import AccountOverview from "./overview";
import YubikeyOTPView from "./yubikey_otp";
import TwoFactorBackupCodeView from "./backup_code";
import GoogleAuthenticatorView from "./google_authenticator";
import WebauthnView from "./webauthn";


const TwoFactorView = (props) => {
    return (
        <Switch>
            <Route path="/two-factor/yubikey-otp/">
                <YubikeyOTPView />
            </Route>
            <Route path="/two-factor/google-authenticator/">
                <GoogleAuthenticatorView />
            </Route>
            <Route path="/two-factor/webauthn/">
                <WebauthnView />
            </Route>
            <Route path="/two-factor/backup-code/">
                <TwoFactorBackupCodeView />
            </Route>
            <Route path="/two-factor/">
                <AccountOverview />
            </Route>
        </Switch>
    );
}
export default TwoFactorView;
