import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from '@mui/material/Alert';
import { Link, useLocation } from "react-router-dom";

import config from "../../services/config";

import Logo from "../../components/logo";
import CSRFToken from "../../components/csrftoken";
import Copyright from "../../components/copyright";
import PrimaryButton from "../../components/button/primary-button";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const TwoFactorBackupCodeView = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const classes = useStyles();
    const [backupCode, setBackupCode] = useState("");
    const errors = config.getErrors();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Logo />
                <Typography component="h1" variant="h5">
                    {t("BACKUP_CODE")}
                </Typography>
                <form className={classes.form} noValidate method="post">
                    <CSRFToken />
                    <TextField
                        variant="outlined"
                        margin="dense"
                        autoFocus
                        required
                        fullWidth
                        id="backupCode"
                        label={t("BACKUP_CODE")}
                        name="backup_code"
                        autoComplete="backupCode"
                        error={errors && "backup_code" in errors}
                        helperText={errors && "backup_code" in errors ? t(errors["backup_code"][0]["message"]) : t("PLEASE_ENTER_BACKUP_CODE")}
                        value={backupCode}
                        onChange={(event) => {
                            setBackupCode(event.target.value);
                        }}
                    />
                    <PrimaryButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={!backupCode}
                        className={classes.submit}
                    >
                        {t("SEND")}
                    </PrimaryButton>
                    {errors && "__all__" in errors && (
                        <Alert severity="error" style={{ marginBottom: "10px" }}>
                            {t(errors["__all__"][0]["message"])}
                        </Alert>
                    )}
                    <Grid container>
                        <Grid item xs>
                            <Link to={{ pathname: "/signin/", search: location.search }}>{t("ABORT")}</Link>
                        </Grid>
                        <Grid item>
                            <Link to={{ pathname: "/two-factor/", search: location.search }}>{t("OTHER_METHOD")}</Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default TwoFactorBackupCodeView;
