import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";

import Link from "@mui/material/Link";
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import config from "../services/config";
import helperService from "../services/helper";
import userService from "../services/user";

import Logo from "../components/logo";
import CSRFToken from "../components/csrftoken";
import Copyright from "../components/copyright";
import PrimaryButton from "../components/button/primary-button";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const SignUpView = (props) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const location = useLocation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [errors, setErrors] = useState(config.getErrors());

    const onSignUp = () => {
        const minSpecialCharacterGroups = config.minSpecialCharacterGroups();
        const minPasswordLength = config.getMinPasswordLength();

        if (password !== password2) {
            setErrors({
                password2: ["PASSWORDS_DIDNT_MATCH"],
            });
        } else if (password.length < minPasswordLength) {
            setErrors({
                password: ["PASSWORD_TOO_SHORT"],
            });
        } else {
            let complexity = 0;
            if (helperService.hasNumber(password)) {
                complexity = complexity + 1;
            }
            if (helperService.hasUppercaseLetter(password)) {
                complexity = complexity + 1;
            }
            if (helperService.hasLowercaseLetter(password)) {
                complexity = complexity + 1;
            }
            if (helperService.hasSpecialCharacter(password)) {
                complexity = complexity + 1;
            }
            if (complexity < minSpecialCharacterGroups) {
                setErrors({
                    password: ["PASSWORD_NOT_COMPLEX_ENOUGH"],
                });
            } else {
                //this.signupForm.current.submit();
                userService.signup(email, password).then(
                    (response) => {
                        if (response.hasOwnProperty('redirect_url')) {
                            window.location.href = response['redirect_url']
                        }
                    },
                    (errors) => {
                        setPassword('')
                        setErrors(errors)
                    }
                );
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Helmet>
                <title>{t("SIGN_UP")} | powered by QeyID</title>
            </Helmet>
            <CssBaseline />
            <div className={classes.paper}>
                <Logo />
                <Typography component="h1" variant="h5">
                    {t("SIGN_UP")}
                </Typography>
                <form className={classes.form} noValidate method="post">
                    <CSRFToken />
                    <TextField
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        id="email"
                        label={t("EMAIL_ADDRESS")}
                        name="email"
                        autoComplete="email"
                        error={errors && "email" in errors}
                        helperText={errors && "email" in errors ? t(errors["email"][0]) : ""}
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        name="password"
                        label={t("PASSWORD")}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        error={errors && "password" in errors}
                        helperText={errors && "password" in errors ? t(errors["password"][0]) : ""}
                        value={password}
                        onChange={(event) => {
                            setPassword(event.target.value);
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        name="password2"
                        label={t("PASSWORD_REPEAT")}
                        type="password"
                        id="password2"
                        error={errors && "password2" in errors}
                        helperText={errors && "password2" in errors ? t(errors["password2"][0]) : ""}
                        value={password2}
                        onChange={(event) => {
                            setPassword2(event.target.value);
                        }}
                    />
                    <PrimaryButton
                        fullWidth
                        variant="contained"
                        disabled={!email || !password || !password2 || password !== password2}
                        className={classes.submit}
                        onClick={onSignUp}
                    >
                        {t("SIGN_UP")}
                    </PrimaryButton>
                    <Grid container>
                        <Grid item>
                            <Link
                                to={{ pathname: "/signin/", search: location.search }}
                                component={RouterLink}
                                color="inherit"
                            >
                                {t("HAVE_AN_ACCOUNT_SIGN_IN")}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default SignUpView;
