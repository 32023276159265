import React from "react";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

import YubikeyOTPListTable from "./yubikey_otp_list";

import Logo from "../../components/logo";
import Copyright from "../../components/copyright";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    content: {
        width: "100%",
        marginTop: theme.spacing(4),
    },
}));

const YubikeyOTP = (props) => {

    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <Logo />
                <Typography component="h1" variant="h5">
                    {t("YUBIKEY_OTP")}
                </Typography>
                <Paper square elevation={0} className={classes.content}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} color="inherit" to="/account/">
                            {t("ACCOUNT")}
                        </Link>
                        <Typography color="textPrimary">{t("YUBIKEY_OTP")}</Typography>
                    </Breadcrumbs>
                    <YubikeyOTPListTable />
                </Paper>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );

}

export default YubikeyOTP;
