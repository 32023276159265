import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

import Logo from "../../components/logo";
import Copyright from "../../components/copyright";
import CSRFToken from "../../components/csrftoken";
import TextFieldQrCode from "../../components/text-field/qr";
import PrimaryButton from "../../components/button/primary-button";
import config from "../../services/config";

import googleAuthenticatorService from "../../services/google-authenticator";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    content: {
        width: "100%",
        marginTop: theme.spacing(4),
    },
    textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    h2: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
}));

const GoogleAuthenticatorCreate = (props) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [errors, setErrors] = useState({});
    const [title, setTitle] = useState("");
    const [googleAuthenticatorId, setGoogleAuthenticatorId] = useState("");
    const [googleAuthenticatorUri, setGoogleAuthenticatorUri] = useState("");
    const [googleAuthenticatorToken, setGoogleAuthenticatorToken] = useState("");

    const onCreate = () => {
        googleAuthenticatorService.createGoogleAuthenticator(title).then(
            (data) => {
                // load step 2
                setGoogleAuthenticatorId(data.id);
                setGoogleAuthenticatorUri("otpauth://totp/" + config.getName() + ":" + data.username + "?secret=" + data.secret);
            },
            (response) => {
                console.log(response);
            }
        );
    };

    const onActivate = () => {
        googleAuthenticatorService.verifyGoogleAuthenticator(googleAuthenticatorId, title, googleAuthenticatorToken).then(
            (data) => {
                history.goBack();
            },
            (response) => {
                setErrors(errors)
            }
        );
    };

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <Logo />
                <Typography component="h1" variant="h5">
                    {t("CREATE_GOOGLE_AUTHENTICATOR")}
                </Typography>
                <Paper square elevation={0} className={classes.content}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} color="inherit" to="/account/">
                            {t("ACCOUNT")}
                        </Link>
                        <Link component={RouterLink} color="inherit" to="/account/google-authenticator/">
                            {t("GOOGLE_AUTHENTICATOR")}
                        </Link>
                        <Typography color="textPrimary">{t("CREATE")}</Typography>
                    </Breadcrumbs>
                    <form className={classes.form} noValidate method="post">
                        <CSRFToken />
                        {!Boolean(googleAuthenticatorUri) && (
                            <>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="dense"
                                            id="title"
                                            label={t("TITLE")}
                                            name="title"
                                            required
                                            autoComplete="title"
                                            error={errors && "title" in errors}
                                            helperText={errors && "title" in errors ? t(errors["title"][0]) : ""}
                                            value={title}
                                            onChange={(event) => {
                                                setTitle(event.target.value)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <div className={classes.buttons}>
                                    <PrimaryButton
                                        variant="contained"
                                        className={classes.submit}
                                        onClick={onCreate}
                                        disabled={!title}
                                    >
                                        {t("CREATE")}
                                    </PrimaryButton>
                                </div>
                            </>
                        )}
                        {Boolean(googleAuthenticatorUri) && (
                            <>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextFieldQrCode
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="dense"
                                            value={googleAuthenticatorUri}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="dense"
                                            id="googleAuthenticatorToken"
                                            type="number"
                                            label={t("CODE")}
                                            name="googleAuthenticatorToken"
                                            required
                                            autoComplete="googleAuthenticatorToken"
                                            error={errors && "google_authenticator_token" in errors}
                                            helperText={errors && "google_authenticator_token" in errors ? t(errors["google_authenticator_token"][0]) : ""}
                                            value={googleAuthenticatorToken}
                                            onChange={(event) => {
                                                setGoogleAuthenticatorToken(event.target.value)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <div className={classes.buttons}>
                                    <PrimaryButton
                                        variant="contained"
                                        disabled={!googleAuthenticatorToken}
                                        className={classes.submit}
                                        onClick={onActivate}
                                    >
                                        {t("ACTIVATE")}
                                    </PrimaryButton>
                                </div>
                            </>
                        )}
                    </form>
                </Paper>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default GoogleAuthenticatorCreate;
