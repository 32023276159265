import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// import moment from 'moment'

let staticPath;
if (process.env.NODE_ENV === "development") {
    staticPath = "/static";
} else {
    staticPath = process.env.STATIC_PATH;
}

i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: "en",
        debug: process.env.NODE_ENV === "development",
        backend: {
            // for all available options read the backend's repository readme file
            loadPath: staticPath + "/frontend/locales/{{lng}}/{{ns}}.json",
        },

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
