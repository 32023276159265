import apiServer from "./api-server";
import helperService from "./helper";


/**
 * Returns the current origin
 *
 * @returns {string} Returns the current origin
 */
function getOrigin() {
    const parsedUrl = helperService.parseUrl(window.location.href)
    return parsedUrl.base_url;
}

/**
 * Returns the details of a specific google authenticator
 *
 * @returns {Promise<object>}
 */
function getWebauthns() {
    return new Promise((resolve, reject) => {
        return apiServer.readWebauthns().then(
            (response) => {
                return resolve(response.data.webauthns);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_65035168-ccfa-4c54-bfcc-cedfe7b530ab");
                reject(response);
            }
        );
    });
}

/**
 *
 * update a google authenticator
 *
 * @param {string} title
 *
 * @returns {Promise<unknown>}
 */
function createWebauthn(title) {
    return new Promise((resolve, reject) => {
        return apiServer.createWebauthn(title, getOrigin()).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * deletes a webauthn
 *
 * @param {string} googleAuthenticatorId
 *
 * @returns {Promise<unknown>}
 */
function deleteWebauthn(googleAuthenticatorId) {
    return new Promise((resolve, reject) => {
        return apiServer.deleteWebauthn(googleAuthenticatorId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 * Activates a webauthn
 *
 * @param {uuid} webauthnId The webauthn id
 * @param {string} credential The credentials passed by the browser
 *
 * @returns {Promise<unknown>}
 */
function activateWebauthn(webauthnId, credential) {
    return new Promise((resolve, reject) => {
        return apiServer.activateWebauthn(webauthnId, credential).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

const service = {
    getWebauthns,
    deleteWebauthn,
    createWebauthn,
    activateWebauthn,
};

export default service;
