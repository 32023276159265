import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import MUIDataTable from "mui-datatables";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const Table = (props) => {
    const { t } = useTranslation();
    const { title, data, columns, onCreate, onDelete, onRowClick, onEdit, options } = props;

    let defaultOptions = {
        textLabels: {
            body: {
                noMatch: t("TABLE_BODY_NO_MATCH"),
                toolTip: t("TABLE_BODY_TOOL_TIP"),
                columnHeaderTooltip: (column) => t("TABLE_BODY_COLUMN_HEADER_TOOLTIP") + " " + column.label,
            },
            pagination: {
                next: t("TABLE_PAGINATION_NEXT"),
                previous: t("TABLE_PAGINATION_PREVIOUS"),
                rowsPerPage: t("TABLE_PAGINATION_ROWS_PER_PAGE"),
                displayRows: t("TABLE_PAGINATION_DISPLAY_ROWS"),
            },
            toolbar: {
                search: t("TABLE_TOOLBAR_SEARCH"),
                downloadCsv: t("TABLE_TOOLBAR_DOWNLOAD_CSV"),
                print: t("TABLE_TOOLBAR_PRINT"),
                viewColumns: t("TABLE_TOOLBAR_VIEW_COLUMNS"),
                filterTable: t("TABLE_TOOLBAR_FILTER_TABLE"),
            },
            filter: {
                all: t("TABLE_FILTER_ALL"),
                title: t("TABLE_FILTER_TITLE"),
                reset: t("TABLE_FILTER_RESET"),
            },
            viewColumns: {
                title: t("TABLE_VIEW_COLUMNS_TITLE"),
                titleAria: t("TABLE_VIEW_COLUMNS_TITLE_ARIA"),
            },
            selectedRows: {
                text: t("TABLE_SELECTED_ROWS_TEXT"),
                delete: t("TABLE_SELECTED_ROWS_DELETE"),
                deleteAria: t("TABLE_SELECTED_ROWS_DELETE_ARIA"),
            },
        },
        selectableRows: "none",
        setTableProps: () => {
            return {
                padding: "none",
                size: "small",
            };
        },
        onRowClick: onRowClick,
    };

    if (onCreate) {
        defaultOptions["customToolbar"] = () => {
            return (
                <Tooltip title={t("CREATE")}>
                    <IconButton onClick={onCreate} size="large">
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            );
        };
    }

    let modifiedColumns = columns;

    if (onEdit) {
        modifiedColumns.push({
            name: t("EDIT"),
            options: {
                filter: true,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton
                            onClick={() => {
                                onEdit(tableMeta.rowData);
                            }}
                            size="large">
                            <EditIcon />
                        </IconButton>
                    );
                },
            },
        });
    }

    if (onDelete) {
        modifiedColumns.push({
            name: t("DELETE"),
            options: {
                filter: true,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton
                            onClick={() => {
                                onDelete(tableMeta.rowData);
                            }}
                            size="large">
                            <DeleteIcon />
                        </IconButton>
                    );
                },
            },
        });
    }

    return <MUIDataTable title={title} data={data} columns={modifiedColumns} options={Object.assign({}, defaultOptions, options)} />;

}

Table.propTypes = {
    title: PropTypes.string,
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    options: PropTypes.object.isRequired,
    onCreate: PropTypes.func,
    onDelete: PropTypes.func,
    onRowClick: PropTypes.func,
    onEdit: PropTypes.func,
};

export default Table;
