import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from '@mui/material/Alert';
import { Link, useLocation } from "react-router-dom";


import config from "../../services/config";
import converterService from "../../services/converter";

import Logo from "../../components/logo";
import CSRFToken from "../../components/csrftoken";
import Copyright from "../../components/copyright";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const WebauthnView = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const classes = useStyles();
    const [errors, setErrors] = useState(config.getErrors());
    const formRef = useRef(null);
    const webauthnCredentialInputRef = useRef(null);

    React.useEffect( () => {
        async function solveWebauthn() {

            const webauthn = config.getWebauthn()
            if (typeof webauthn === "undefined") {
                return
            }

            webauthn.options.challenge = Uint8Array.from(webauthn.options.challenge, c => c.charCodeAt(0))
            for (let i = 0; i < webauthn.options.allowCredentials.length; i++) {
                webauthn.options.allowCredentials[i]['id'] = converterService.base64ToArrayBuffer(webauthn.options.allowCredentials[i]['id'].replace(/-/g, '+').replace(/_/g, '/'))
            }

            let credential;
            try {
                credential = await navigator.credentials.get({
                    publicKey: webauthn.options
                });
            } catch (error) {
                setErrors({"__all__": [{"message": 'WEBAUTHN_AUTHENTICATION_FAILED'}]})
                return
            }

            const convertedCredential = {
                "id": credential.id,
                "rawId": credential.id,
                "type": credential.type,
                "authenticatorAttachment": credential.authenticatorAttachment,
                "response": {
                    "authenticatorData": converterService.arrayBufferToBase64(credential.response.authenticatorData),
                    "clientDataJSON": converterService.arrayBufferToBase64(credential.response.clientDataJSON),
                    "signature": converterService.arrayBufferToBase64(credential.response.signature),
                    "userHandle": converterService.arrayBufferToBase64(credential.response.userHandle),
                },
            }

            // fill input field
            webauthnCredentialInputRef.current.value = JSON.stringify(convertedCredential);

            // submit form
            if (typeof formRef.current.requestSubmit === 'function') {
              formRef.current.requestSubmit();
            } else {
              formRef.current.dispatchEvent(
                new Event('submit', { cancelable: true, bubbles: true })
              );
            }
        }
        solveWebauthn();
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Logo />
                <Typography component="h1" variant="h5">
                    {t("FIDO2_WEBAUTHN")}
                </Typography>
                <form className={classes.form} noValidate method="post" ref={formRef}>
                    <CSRFToken />
                    <input
                        ref={webauthnCredentialInputRef}
                        id="webauthn_credential"
                        name="webauthn_credential"
                        hidden
                    />
                    <Alert severity="info" style={{ marginTop: "10px" }}>
                        {t("SOLVE_THE_WEBAUTHN_CHALLENGE_EXPLAINED")}
                    </Alert>
                    {errors && "__all__" in errors && (
                        <Alert severity="error" style={{ marginBottom: "10px" }}>
                            {t(errors["__all__"][0]["message"])}
                        </Alert>
                    )}
                    {errors && "webauthn_credential" in errors && (
                        <Alert severity="error" style={{ marginBottom: "10px" }}>
                            {t(errors["webauthn_credential"][0]["message"])}
                        </Alert>
                    )}
                    <Grid container>
                        <Grid item xs>
                            <Link to={{ pathname: "/signin/", search: location.search }}>{t("ABORT")}</Link>
                        </Grid>
                        <Grid item>
                            <Link to={{ pathname: "/two-factor/", search: location.search }}>{t("OTHER_METHOD")}</Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default WebauthnView;
