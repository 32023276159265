import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import backupCodeService from "../../services/backup-code";
import TextField from "@mui/material/TextField";
import PrimaryButton from "../../components/button/primary-button";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    options: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    container: {
        marginBottom: theme.spacing(1),
    },
    textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },
    code: {
        fontFamily: "Monospace",
        fontSize: "12.5714px",
    },
}));

const AccountOverviewBackupCodes = (props) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const [errors, setErrors] = useState({});
    const [backupCodes, setBackupCodes] = useState("");

    const generateBackupCodes = () => {
        backupCodeService.createBackupCodes().then(
            (backupCodes) => {
                // load step 2
                setBackupCodes(backupCodes.join("\n"))
            },
            (response) => {
                console.log(response);
            }
        );
    };

    const reset = () => {
        setBackupCodes("")
    };

    return (
        <div className={classes.wrapper}>
            {backupCodes.length === 0 ? (
                <>
                    <Alert variant="outlined" severity="warning" className={classes.container}>
                        {t("GENERATING_NEW_BACKUP_CODES_INVALIDATE_ALL_PREVIOUS_GENERATED_BACKUP_CODES")}
                    </Alert>
                    <Grid container>
                        <Grid item xs={12}>
                            <Button color="primary" onClick={generateBackupCodes}>
                                {t("GENERATE_NEW_BACKUP_CODES")}
                            </Button>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid container className={classes.container}>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense"
                                required
                                disabled
                                multiline
                                InputProps={{
                                    classes: {
                                        input: classes.code,
                                    },
                                }}
                                id="backupCodes"
                                label={t("BACKUP_CODES")}
                                name="backupCodes"
                                autoComplete="backupCodes"
                                error={errors && "backup_codes" in errors}
                                helperText={errors && "backup_codes" in errors ? t(errors["backup_codes"][0]) : t("STORE_BACKUP_CODES_IN_A_SECURE_PLACE")}
                                value={backupCodes}
                                onChange={(event) => {
                                    setBackupCodes(event.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid item xs={12}>
                            <PrimaryButton
                                variant="contained"
                                className={classes.submit}
                                onClick={reset}
                            >
                                {t("BACK")}
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );

}

export default AccountOverviewBackupCodes;
