import React from "react";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Alert from '@mui/material/Alert';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    options: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    container: {
        marginBottom: theme.spacing(1),
    },
}));

const AccountOverviewProfile = (props) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const configureGoogleAuthenticator = () => {
        history.push("/account/google-authenticator/");
    };

    const configureWebauthn = () => {
        history.push("/account/webauthn/");
    };

    const configureYubikeyOTP = () => {
        history.push("/account/yubikey-otp/");
    };

    return (
        <div className={classes.wrapper}>
            <Alert variant="outlined" severity="warning" className={classes.container}>
                {t("GENERATE_BACKUP_CODES_BEFORE")}
            </Alert>
            <Grid container>
                <Grid item xs={6}>
                    <Typography align="right" className={classes.options}>
                        {t("GOOGLE_AUTHENTICATOR")}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Button color="primary" startIcon={<EditIcon />} onClick={configureGoogleAuthenticator}>
                        {t("CONFIGURE")}
                    </Button>
                </Grid>
            </Grid>
            <Grid container className={classes.container}>
                <Grid item xs={6}>
                    <Typography align="right" className={classes.options}>
                        {t("YUBIKEY_OTP")}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Button color="primary" startIcon={<EditIcon />} onClick={configureYubikeyOTP}>
                        {t("CONFIGURE")}
                    </Button>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography align="right" className={classes.options}>
                        {t("FIDO2_WEBAUTHN")}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Button color="primary" startIcon={<EditIcon />} onClick={configureWebauthn}>
                        {t("CONFIGURE")}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default AccountOverviewProfile;
