import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from '@mui/material/Alert';
import Grid from "@mui/material/Grid";
import {Helmet} from "react-helmet";

import config from "../services/config";

import Logo from "../components/logo";
import CSRFToken from "../components/csrftoken";
import PrimaryButton from "../components/button/primary-button";
import Copyright from "../components/copyright";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const PasswordResetView = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const errors = config.getErrors();
    const [email, setEmail] = useState("");

    const back = () => {
        window.location.href = '/'
    }

    return (
        <Container component="main" maxWidth="xs">
            <Helmet>
                <title>{t("PASSWORD_RESET")} | powered by QeyID</title>
            </Helmet>
            <CssBaseline />
            <div className={classes.paper}>
                <Logo />
                <Typography component="h1" variant="h5">
                    {t("PASSWORD_RESET")}
                </Typography>
                <form className={classes.form} noValidate method="post">
                    <CSRFToken />
                    <TextField
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        id="email"
                        label={t("EMAIL_ADDRESS")}
                        name="email"
                        autoComplete="email"
                        error={errors && "email" in errors}
                        helperText={errors && "email" in errors ? t(errors["email"][0]["message"]) : ""}
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                onClick={back}
                                fullWidth
                                style={{ color: "inherit" }}
                                className={classes.submit}
                            >
                                {t("BACK")}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <PrimaryButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={!email}
                                className={classes.submit}
                            >
                                {t("SEND")}
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                    {errors && "__all__" in errors && (
                        <Alert severity="error" style={{ marginBottom: "10px" }}>
                            {t(errors["__all__"][0]["message"])}
                        </Alert>
                    )}
                </form>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default PasswordResetView;
