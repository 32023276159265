/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from "prop-types";
import stateService from "../services/states";

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
}));

const StateSelectField = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { fullWidth, variant, margin, helperText, error, required, onChange, value, className } = props;
    const [states, setStates] = useState([]);

    React.useEffect( () => {
        loadStates();
    }, []);
    React.useEffect( () => {
        loadStates();
    }, [props.country]);

    const loadStates = () => {
        stateService.getStates().then(
            (states) => {
                if (props.filter) {
                    setStates(Object.keys(states)
                        .filter((code) => props.filter(code))
                        .map((code) => {
                            return { code: code, label: states[code] };
                        }));
                } else {
                    setStates(Object.keys(states).map((code) => {
                        return { code: code, label: states[code] };
                    }))
                }
            },
            (response) => {
                console.log(response);
            }
        );
    }

    let defaulValue = null;
    if (value && states && states.length) {
        defaulValue = states.find(function (state) {
            return state.code === value;
        });
    }

    return (
        <Autocomplete
            options={states}
            classes={{
                option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => {
                return option ? option.label : "";
            }}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    {option.label} ({option.code})
                </li>
            )}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            isOptionEqualToValue={(option, value) => {
                return Boolean(option) && Boolean(value) && option.code === value.code;
            }}
            value={defaulValue}
            renderInput={(params) => (
                <TextField
                    className={className}
                    {...params}
                    label={t("STATE")}
                    required={required}
                    margin={margin}
                    variant={variant}
                    helperText={helperText}
                    error={error}
                    fullWidth={fullWidth}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}

StateSelectField.defaultProps = {
    error: false,
};

StateSelectField.propTypes = {
    value: PropTypes.string,
    fullWidth: PropTypes.bool,
    error: PropTypes.bool,
    required: PropTypes.bool,
    helperText: PropTypes.string,
    variant: PropTypes.string,
    margin: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    filter: PropTypes.func,
};

export default StateSelectField;
