/**
 * Converter service to convert between data formats
 */

/**
 * Converts an arrayBuffer to Base64
 *
 * @param buffer
 *
 * @returns {string} The Base64 representation of the buffer
 */
function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array( buffer );
    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

/**
 * Converts an Base64 encoded string to arrayBuffer
 *
 * @param base64
 *
 * @returns {string} The buffer representation of the base64 encoded string
 */
function base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

const converterService = {
    arrayBufferToBase64: arrayBufferToBase64,
    base64ToArrayBuffer: base64ToArrayBuffer,
};

export default converterService;