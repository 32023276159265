import React from "react";

import { Route, Switch } from "react-router-dom";
import AccountOverview from "./overview";
import YubikeyOTPCreate from "./yubikey_otp_create";
import YubikeyOTP from "./yubikey_otp";
import WebauthnCreate from "./webauthn_create";
import Webauthn from "./webauthn";
import GoogleAuthenticator from "./google_authenticator";
import GoogleAuthenticatorCreate from "./google_authenticator_create";

const AccountView = (props) => {
        return (
            <Switch>
                <Route path="/account/yubikey-otp/create/">
                    <YubikeyOTPCreate />
                </Route>
                <Route path="/account/yubikey-otp/">
                    <YubikeyOTP />
                </Route>
                <Route path="/account/webauthn/create/">
                    <WebauthnCreate />
                </Route>
                <Route path="/account/webauthn/">
                    <Webauthn />
                </Route>
                <Route path="/account/google-authenticator/create/">
                    <GoogleAuthenticatorCreate />
                </Route>
                <Route path="/account/google-authenticator/">
                    <GoogleAuthenticator />
                </Route>
                <Route path="/account/">
                    <AccountOverview />
                </Route>
            </Switch>
        );
}
export default AccountView;
