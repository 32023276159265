import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import webauthnService from "../../services/webauthn";
import Table from "../../components/table";

const WebauthnListTable = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [webauthns, setWebauthns] = useState([]);

    React.useEffect( () => {
        loadWebauthns();
    }, []);

    const loadWebauthns = () => {
        webauthnService.getWebauthns().then(
            (webauthns) => {
                setWebauthns(webauthns.map((webauthn) => [webauthn.id, webauthn.title]));
            },
            (response) => {
                console.log(response);
            }
        );
    }

    const onCreate = () => {
        history.push("/account/webauthn/create/");
    };
    const onDelete = (rowData) => {
        webauthnService.deleteWebauthn(rowData[0]).then(
            () => {
                loadWebauthns();
            },
            (response) => {
                console.log(response);
            }
        );
    };

    const columns = [{ name: t("ID"), options: { display: "false" } }, { name: t("TITLE") }];

    const options = {
        filterType: "checkbox",
    };

    return <Table data={webauthns} columns={columns} options={options} onCreate={onCreate} onDelete={onDelete} />;
}

export default WebauthnListTable;
