import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

import Logo from "../../components/logo";
import Copyright from "../../components/copyright";
import CSRFToken from "../../components/csrftoken";
import PrimaryButton from "../../components/button/primary-button";

import yubikeyOTPService from "../../services/yubikey-otp";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    content: {
        width: "100%",
        marginTop: theme.spacing(4),
    },
    textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    h2: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
}));

const YubikeyOTPCreate = (props) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [errors, setErrors] = useState({});
    const [title, setTitle] = useState("");
    const [yubikeyOTP, setYubikeyOTP] = useState("");

    const onCreate = () => {
        yubikeyOTPService.createYubikeyOTP(title, yubikeyOTP).then(
            (data) => {
                history.goBack();
            },
            (errors) => {
                setErrors(errors)
            }
        );
    };

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <Logo />
                <Typography component="h1" variant="h5">
                    {t("CREATE_YUBIKEY_OTP")}
                </Typography>
                <Paper square elevation={0} className={classes.content}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} color="inherit" to="/account/">
                            {t("ACCOUNT")}
                        </Link>
                        <Link component={RouterLink} color="inherit" to="/account/yubikey-otp/">
                            {t("YUBIKEY_OTP")}
                        </Link>
                        <Typography color="textPrimary">{t("CREATE")}</Typography>
                    </Breadcrumbs>
                    <form className={classes.form} noValidate method="post">
                        <CSRFToken />

                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    margin="dense"
                                    id="title"
                                    label={t("TITLE")}
                                    name="title"
                                    required
                                    autoComplete="title"
                                    error={errors && "title" in errors}
                                    helperText={errors && "title" in errors ? t(errors["title"][0]) : ""}
                                    value={title}
                                    onChange={(event) => {
                                        setTitle(event.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    margin="dense"
                                    id="yubikeyOTP"
                                    label={t("YUBIKEY_OTP_CODE")}
                                    name="yubikeyOTP"
                                    required
                                    autoComplete="yubikeyOTP"
                                    error={errors && "yubikey_otp" in errors}
                                    helperText={errors && "yubikey_otp" in errors ? t(errors["yubikey_otp"][0]) : ""}
                                    value={yubikeyOTP}
                                    onChange={(event) => {
                                        setYubikeyOTP(event.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <div className={classes.buttons}>
                            <PrimaryButton
                                variant="contained"
                                disabled={!title || !yubikeyOTP}
                                className={classes.submit}
                                onClick={onCreate}
                            >
                                {t("CREATE")}
                            </PrimaryButton>
                        </div>
                    </form>
                </Paper>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default YubikeyOTPCreate;
