const config = window.qeyidConfig;

function getCSRFToken() {
    return config.csrf_token;
}
function getErrors() {
    return config.errors;
}
function getLegalNoticeUrl() {
    return (config.client && config.client.legal_notice_url) || config.site_config.legal_notice_url;
}
function getName() {
    return (config.client && config.client.name) || config.site_config.name;
}
function getLogoUrl() {
    return (config.client && config.client.logo_url) || config.site_config.logo_url;
}
function getPrimaryColor() {
    return (config.client && config.client.primary_color) || config.site_config.primary_color;
}
function getPrimaryColorText() {
    return (config.client && config.client.primary_color_text) || config.site_config.primary_color_text;
}
function getRequireVerifiedEmail() {
    return (config.client && config.client.require_verified_email) || config.site_config.require_verified_email;
}
function getPrivacyPolicyUrl() {
    return (config.client && config.client.privacy_policy_url) || config.site_config.privacy_policy_url;
}
function getMinPasswordLength() {
    return (config.client && config.client.min_password_length) || config.site_config.min_password_length;
}
function minSpecialCharacterGroups() {
    return (config.client && config.client.min_special_character_groups) || config.site_config.min_special_character_groups;
}
function isRegistrationEnabled() {
    if (config.client) {
        return config.client.registration_enabled;
    } else {
        return config.site_config.registration_enabled;
    }
}

function getScopes() {
    return config.scopes;
}
function getWebauthn() {
    return config.webauthn;
}

// all the "user" info

function getUserIsEmailVerified() {
    return config.user && config.user.email_verified;
}

function getUserGoogleAuthenticatorEnabled() {
    return config.user && config.user.google_authenticator_enabled;
}

function getUserWebauthnEnabled() {
    return config.user && config.user.webauthn_enabled;
}

function getUserYubikeyOTPEnabled() {
    return config.user && config.user.yubikey_otp_enabled;
}

// all the "params"

function getParamClientId() {
    return config.params && config.params.client_id;
}
function getParamRedirectUri() {
    return config.params && config.params.redirect_uri;
}
function getParamResponseType() {
    return config.params && config.params.response_type;
}
function getParamScope() {
    return config.params && config.params.scope;
}
function getParamState() {
    return config.params && config.params.state;
}
function getParamNonce() {
    return config.params && config.params.nonce;
}
function getParamCodeChallenge() {
    return config.params && config.params.code_challenge;
}
function getParamCodeChallengeMethod() {
    return config.params && config.params.code_challenge_method;
}
const service = {
    getCSRFToken,
    getErrors,
    getLegalNoticeUrl,
    getName,
    getLogoUrl,
    getPrimaryColor,
    getPrimaryColorText,
    getRequireVerifiedEmail,
    getPrivacyPolicyUrl,
    minSpecialCharacterGroups,
    getMinPasswordLength,
    isRegistrationEnabled,
    getScopes,
    getWebauthn,
    getUserIsEmailVerified,
    getUserGoogleAuthenticatorEnabled,
    getUserWebauthnEnabled,
    getUserYubikeyOTPEnabled,
    getParamClientId,
    getParamRedirectUri,
    getParamResponseType,
    getParamScope,
    getParamState,
    getParamNonce,
    getParamCodeChallenge,
    getParamCodeChallengeMethod,
};

export default service;
