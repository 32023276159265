import apiServer from "./api-server";

/**
 * Creates new backup codes
 *
 * @returns {Promise<unknown>}
 */
function createBackupCodes() {
    return new Promise((resolve, reject) => {
        return apiServer.createBackupCodes().then(
            (response) => {
                return resolve(response.data.backup_codes);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

const service = {
    createBackupCodes,
};

export default service;
