import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from "date-fns";

import userService from "../../services/user";

import CSRFToken from "../../components/csrftoken";
import CountrySelectField from "../../components/country-select-field";
import StateSelectField from "../../components/state-select-field";
import PrimaryButton from "../../components/button/primary-button";

const useStyles = makeStyles((theme) => ({
    textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    h2: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
}));

const AccountOverviewProfile = (props) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState("");
    const [familyName, setFamilyName] = useState("");
    const [givenName, setGivenName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [nickname, setNickname] = useState("");
    const [preferredUsername, setPreferredUsername] = useState("");
    const [website, setWebsite] = useState("");
    const [birthdate, setBirthdate] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [zoneinfo, setZoneinfo] = useState("");
    const [addressFormatted, setAddressFormatted] = useState("");
    const [addressStreetAddress, setAddressStreetAddress] = useState("");
    const [addressLocality, setAddressLocality] = useState("");
    const [addressRegion, setAddressRegion] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [country, setCountry] = useState("");
    const [updateUserSuccess, setUpdateUserSuccess] = useState("");


    const loadUser = () => {
        userService.getUser().then(
            (user) => {
                setEmail(user.email)
                setFamilyName(user.family_name)
                setGivenName(user.given_name)
                setMiddleName(user.middle_name)
                setNickname(user.nickname)
                setPreferredUsername(user.preferred_username)
                setWebsite(user.website)
                setBirthdate(user.birthdate ? Date.parse(user.birthdate) : user.birthdate)
                setPhoneNumber(user.phone_number)
                setZoneinfo(user.zoneinfo)
                setAddressFormatted("")
                setAddressStreetAddress(user.address_street_address)
                setAddressLocality(user.address_locality)
                setAddressRegion(user.address_region)
                setPostalCode(user.postal_code)
                setCountry(user.country)
            },
            (response) => {
                console.log(response);
            }
        );
    }

    React.useEffect( () => {
        loadUser()
    }, []);

    const onEdit = () => {
        setErrors({})
        let birthdateDateonly = null;
        if (birthdate) {
            birthdateDateonly = format(birthdate, "yyyy-MM-dd");
        }
        userService
            .updateUser(
                email,
                familyName,
                givenName,
                middleName,
                nickname,
                preferredUsername,
                website,
                birthdateDateonly,
                phoneNumber,
                zoneinfo,
                addressFormatted,
                addressStreetAddress,
                addressLocality,
                addressRegion,
                postalCode,
                country
            )
            .then(
                (response) => {
                    loadUser();
                    setErrors({})
                    setUpdateUserSuccess(true)
                    setTimeout(() => {
                        setUpdateUserSuccess(false)
                    }, 5000);
                },
                (errors) => {
                    setErrors(errors)
                }
            );
    };

    return (
        <form className={classes.form} noValidate method="post">
            <CSRFToken />
            <Grid container>
                <Grid item xs={12}>
                    <Typography component="h2" variant="h6" className={classes.h2}>
                        {t("EMAIL")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense"
                        id="email"
                        label={t("EMAIL_ADDRESS")}
                        name="email"
                        autoComplete="email"
                        error={errors && "email" in errors}
                        helperText={errors && "email" in errors ? t(errors["email"][0]) : ""}
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography component="h2" variant="h6" className={classes.h2}>
                        {t("BASIC_PROFILE")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense"
                        id="givenName"
                        label={t("GIVEN_NAME")}
                        name="givenName"
                        autoComplete="givenName"
                        error={errors && "given_name" in errors}
                        helperText={errors && "given_name" in errors ? t(errors["given_name"][0]) : ""}
                        value={givenName}
                        onChange={(event) => {
                            setGivenName(event.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense"
                        id="middleName"
                        label={t("MIDDLE_NAME")}
                        name="middleName"
                        autoComplete="middleName"
                        error={errors && "middle_name" in errors}
                        helperText={errors && "middle_name" in errors ? t(errors["middle_name"][0]) : ""}
                        value={middleName}
                        onChange={(event) => {
                            setMiddleName(event.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense"
                        id="familyName"
                        label={t("FAMILY_NAME")}
                        name="familyName"
                        autoComplete="familyName"
                        error={errors && "family_name" in errors}
                        helperText={errors && "family_name" in errors ? t(errors["family_name"][0]) : ""}
                        value={familyName}
                        onChange={(event) => {
                            setFamilyName(event.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense"
                        id="preferredUsername"
                        label={t("PREFERRED_USERNAME")}
                        name="preferredUsername"
                        autoComplete="preferredUsername"
                        error={errors && "preferred_username" in errors}
                        helperText={errors && "preferred_username" in errors ? t(errors["preferred_username"][0]) : ""}
                        value={preferredUsername}
                        onChange={(event) => {
                            setPreferredUsername(event.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense"
                        id="nickname"
                        label={t("NICKNAME")}
                        name="nickname"
                        autoComplete="nickname"
                        error={errors && "nickname" in errors}
                        helperText={errors && "nickname" in errors ? t(errors["nickname"][0]) : ""}
                        value={nickname}
                        onChange={(event) => {
                            setNickname(event.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense"
                        id="website"
                        label={t("WEBSITE")}
                        name="website"
                        autoComplete="website"
                        error={errors && "website" in errors}
                        helperText={errors && "website" in errors ? t(errors["website"][0]) : ""}
                        value={website}
                        onChange={(event) => {
                            setWebsite(event.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        className={classes.textField}
                        inputVariant="outlined"
                        margin="dense"
                        id="birthdate"
                        label={t("BIRTHDATE")}
                        format="MM/dd/yyyy"
                        value={birthdate}
                        onChange={(birthdate) => {
                            setBirthdate(birthdate)
                        }}
                        KeyboardButtonProps={{
                            "aria-label": "change birthdate",
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography component="h2" variant="h6" className={classes.h2}>
                        {t("ADDRESS")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense"
                        id="addressStreetAddress"
                        label={t("STREET")}
                        name="addressStreetAddress"
                        autoComplete="addressStreetAddress"
                        error={errors && "address_street_address" in errors}
                        helperText={errors && "address_street_address" in errors ? t(errors["address_street_address"][0]) : ""}
                        value={addressStreetAddress}
                        onChange={(event) => {
                            setAddressStreetAddress(event.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense"
                        id="postalCode"
                        label={t("POSTAL_CODE")}
                        name="postalCode"
                        autoComplete="postalCode"
                        error={errors && "postal_code" in errors}
                        helperText={errors && "postal_code" in errors ? t(errors["postal_code"][0]) : ""}
                        value={postalCode}
                        onChange={(event) => {
                            setPostalCode(event.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense"
                        id="addressLocality"
                        label={t("LOCALITY")}
                        name="addressLocality"
                        autoComplete="addressLocality"
                        error={errors && "address_locality" in errors}
                        helperText={errors && "address_locality" in errors ? t(errors["address_locality"][0]) : ""}
                        value={addressLocality}
                        onChange={(event) => {
                            setAddressLocality(event.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CountrySelectField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense"
                        error={errors && "country" in errors}
                        helperText={errors && "country" in errors ? t(errors["country"][0]) : ""}
                        value={country}
                        onChange={(value) => {
                            let addressRegionCopy = addressRegion;
                            if (!addressRegionCopy.startsWith(value + ":")) {
                                addressRegionCopy = "";
                            }
                            setCountry(value ? value.code : "")
                            setAddressRegion(addressRegionCopy)
                        }}
                    />
                </Grid>

                {country && ["CA", "CN", "MX", "US", "AU", "MY"].includes(country) && (
                    <Grid item xs={12}>
                        <StateSelectField
                            className={classes.textField}
                            variant="outlined"
                            margin="dense"
                            error={errors && "address_region" in errors}
                            helperText={errors && "address_region" in errors ? t(errors["address_region"][0]) : ""}
                            value={addressRegion}
                            filter={(code) => {
                                return code.startsWith(country + ":");
                            }}
                            onChange={(value) => {
                                setAddressRegion(value ? value.code : "")
                            }}
                            country={country}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography component="h2" variant="h6" className={classes.h2}>
                        {t("PHONE_NUMBER")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="dense"
                        id="phoneNumber"
                        label={t("PHONE_NUMBER")}
                        name="phoneNumber"
                        autoComplete="phoneNumber"
                        error={errors && "phone_number" in errors}
                        helperText={errors && "phone_number" in errors ? t(errors["phone_number"][0]) : ""}
                        value={phoneNumber}
                        onChange={(event) => {
                            setPhoneNumber(event.target.value)
                        }}
                    />
                </Grid>
            </Grid>
            <div className={classes.buttons}>
                <PrimaryButton
                    variant="contained"
                    className={classes.submit}
                    onClick={onEdit}
                >
                    {t("SAVE")}
                </PrimaryButton>
            </div>
            {updateUserSuccess && (
                <Alert variant="outlined" severity="success">
                    {t("SAVE_SUCCESSFUL")}
                </Alert>
            )}
        </form>
    );

}

export default AccountOverviewProfile;
