import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";

import config from "../services/config";

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    logo: {
        margin: theme.spacing(1),
    },
}));

const Logo = (props) => {
    const classes = useStyles();
    const logoUrl = config.getLogoUrl();
    return (
        <>
            {logoUrl ? (
                <img src={logoUrl} alt="logo" className={classes.logo} />
            ) : (
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
            )}
        </>
    );
}

export default Logo;
