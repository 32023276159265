import React from "react";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import Logo from "../components/logo";
import Copyright from "../components/copyright";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
}));

const SignUpRequireEmailVerificationView = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Logo />
                <Typography component="h1" variant="h5">
                    {t("SUCCESS")}
                </Typography>
                <Alert severity="info" style={{ marginTop: "10px" }}>
                    {t("SUCCESSFUL_REGISTERED_CHECK_EMAIL_TO_VERIFY_ACCOUNT")}
                </Alert>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default SignUpRequireEmailVerificationView;
