import React from "react";
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link, useLocation } from "react-router-dom";

import Logo from "../../components/logo";
import Copyright from "../../components/copyright";
import config from "../../services/config";
import PrimaryButton from "../../components/button/primary-button";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    content: {
        width: "100%",
        marginTop: theme.spacing(4),
    },
    button: {
        marginBottom: theme.spacing(1),
    },
}));

const AccountOverview = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const location = useLocation();
    const googleAuthenticatorEnabled = config.getUserGoogleAuthenticatorEnabled();
    const webauthnEnabled = config.getUserWebauthnEnabled();
    const yubikeyOTPEnabled = config.getUserYubikeyOTPEnabled();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Logo />
                <Typography component="h1" variant="h5">
                    {t("OVERVIEW")}
                </Typography>
                <div className={classes.content}>
                    {yubikeyOTPEnabled && (
                        <PrimaryButton
                            fullWidth
                            color="primary"
                            component={Link}
                            className={classes.button}
                            to={{ pathname: "/two-factor/yubikey-otp/", search: location.search }}
                        >
                            {t("YUBIKEY_OTP")}
                        </PrimaryButton>
                    )}
                    {googleAuthenticatorEnabled && (
                        <PrimaryButton
                            fullWidth
                            color="primary"
                            component={Link}
                            className={classes.button}
                            to={{ pathname: "/two-factor/google-authenticator/", search: location.search }}
                        >
                            {t("GOOGLE_AUTHENTICATOR")}
                        </PrimaryButton>
                    )}
                    {webauthnEnabled && (
                        <PrimaryButton
                            fullWidth
                            color="primary"
                            component={Link}
                            className={classes.button}
                            to={{ pathname: "/two-factor/webauthn/", search: location.search }}
                        >
                            {t("FIDO2_WEBAUTHN")}
                        </PrimaryButton>
                    )}
                    <PrimaryButton
                        fullWidth
                        color="primary"
                        component={Link}
                        className={classes.button}
                        to={{ pathname: "/two-factor/backup-code/", search: location.search }}
                    >
                        {t("BACKUP_CODE")}
                    </PrimaryButton>
                    <Grid container>
                        <Grid item>
                            <Link to={{ pathname: "/signin/", search: location.search }}>{t("ABORT")}</Link>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default AccountOverview;
