import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";

import Link from "@mui/material/Link";
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from '@mui/material/Alert';

import config from "../services/config";
import userService from "../services/user";
import helperService from "../services/helper";

import Logo from "../components/logo";
import CSRFToken from "../components/csrftoken";
import Copyright from "../components/copyright";
import PrimaryButton from "../components/button/primary-button";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginBottom: theme.spacing(2),
    },
}));

const SignInView = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const location = useLocation();
    const registrationEnabled = config.isRegistrationEnabled();

    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState(config.getErrors());

    const next = () => {
        userService.signinInit(email).then(
            (response) => {
                if (response['authentication_methods'].includes('PASSWORD')) {
                    setShowPassword(true);
                }
            },
            (errors) => {
                setErrors(errors)
            }
        );
    }

    const signin = () => {
        userService.signin(email, password).then(
            (response) => {
                if (response.hasOwnProperty('redirect_url')) {
                    window.location.href = response['redirect_url']
                }
            },
            (errors) => {
                setPassword('')
                setErrors(errors)
            }
        );
    }

    const back = () => {
        setShowPassword(false);
    }

    return (
        <Container component="main" maxWidth="xs">
            <Helmet>
                <title>{t("SIGN_IN")} | powered by QeyID</title>
            </Helmet>
            <CssBaseline />
            <div className={classes.paper}>
                <Logo />
                <Typography component="h1" variant="h5">
                    {t("SIGN_IN")}
                </Typography>
                <form className={classes.form} noValidate>
                    <CSRFToken />
                    <Grid container spacing={0}
                        style={{
                            display: showPassword ? "none" : "flex",
                        }}
                    >
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                required
                                type="email"
                                fullWidth
                                id="email"
                                label={t("EMAIL_ADDRESS")}
                                name="email"
                                autoComplete="email"
                                error={errors && "email" in errors}
                                helperText={errors && "email" in errors ? t(errors["email"][0]) : ""}
                                value={email}
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}
                            style={{
                                padding: 0,
                            }}
                        >
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label={t("REMEMBER_ME")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            &nbsp;
                        </Grid>
                        <Grid item xs={6}>
                            <PrimaryButton
                                onClick={next}
                                fullWidth
                                variant="contained"
                                className={classes.submit}
                                disabled={!email || !helperService.isValidEmail(email)}
                            >
                                {t("NEXT")}
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}
                        style={{
                            display: !showPassword ? "none" : "flex",
                        }}
                    >
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                name="password"
                                label={t("PASSWORD")}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                error={errors && "password" in errors}
                                helperText={errors && "password" in errors ? t(errors["password"][0]) : ""}
                                value={password}
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                onClick={back}
                                fullWidth
                                className={classes.submit}
                                style={{ color: "inherit" }}
                            >
                                {t("BACK")}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <PrimaryButton
                                onClick={signin}
                                fullWidth
                                variant="contained"
                                disabled={!password}
                                className={classes.submit}
                            >
                                {t("SIGN_IN")}
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                    {errors && "__all__" in errors && (
                        <Alert severity="error" style={{ marginBottom: "10px" }}>
                            {t(errors["__all__"][0])}
                        </Alert>
                    )}
                    <Grid container>
                        <Grid item xs>
                            <Link
                                to={{ pathname: "/password-reset/", search: location.search }}
                                component={RouterLink}
                                color="inherit"
                            >
                                {t("FORGOT_PASSWORD")}
                            </Link>
                        </Grid>
                        <Grid item>
                            {registrationEnabled && (
                                <Link
                                    to={{ pathname: "/signup/", search: location.search }}
                                    component={RouterLink}
                                    color="inherit"
                                >
                                    {t("DONT_HAVE_AN_ACCOUNT_SIGN_UP")}
                                </Link>
                            )}
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );

}

export default SignInView;
