import React, { Suspense } from "react";
import { render } from "react-dom";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { I18nextProvider } from "react-i18next";

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import i18n from "./i18n";
import theme from "./theme";
import AccountView from "./views/account/index";
import IndexView from "./views/index";
import SignInView from "./views/signin";
import SignUpView from "./views/signup";
import SignUpRequireEmailVerificationView from "./views/signup-require-email-verification";
import AuthorizeView from "./views/authorize";
import TwoFactorView from "./views/two-factor";
import PasswordResetView from "./views/password-reset";
import PasswordResetConfirmView from "./views/password-reset-confirm";
import PasswordResetDoneView from "./views/password-reset-done";
import PasswordResetCompleteView from "./views/password-reset-complete";
import PasswordResetInvalidLinkView from "./views/password-reset-invalid-link";
import AccountActivationDoneView from "./views/account-activation-done";

const customHistory = createBrowserHistory();

const App = (props) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <I18nextProvider i18n={i18n}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <Router history={customHistory}>
                            <div>
                                <Switch>
                                    <Route path="/signin/">
                                        <SignInView />
                                    </Route>
                                    <Route path="/signup/require-email-verification">
                                        <SignUpRequireEmailVerificationView />
                                    </Route>
                                    <Route path="/signup/">
                                        <SignUpView />
                                    </Route>
                                    <Route path="/account/">
                                        <AccountView />
                                    </Route>
                                    <Route path="/account-activation/done/">
                                        <AccountActivationDoneView />
                                    </Route>
                                    <Route path="/reset/invalid-link/">
                                        <PasswordResetInvalidLinkView />
                                    </Route>
                                    <Route path="/reset/done/">
                                        <PasswordResetCompleteView />
                                    </Route>
                                    <Route path="/reset/">
                                        <PasswordResetConfirmView />
                                    </Route>
                                    <Route path="/password-reset/done/">
                                        <PasswordResetDoneView />
                                    </Route>
                                    <Route path="/password-reset/">
                                        <PasswordResetView />
                                    </Route>
                                    <Route path="/authorize/">
                                        <AuthorizeView />
                                    </Route>
                                    <Route path="/two-factor/">
                                        <TwoFactorView />
                                    </Route>
                                    <Route path="/">
                                        <IndexView />
                                    </Route>
                                </Switch>
                            </div>
                        </Router>
                    </ThemeProvider>
                </StyledEngineProvider>
            </I18nextProvider>
        </LocalizationProvider>
    );
}

export default App;

const container = document.getElementById("app");
render(
    <Suspense fallback="loading...">
        <App />
    </Suspense>,
    container
);
