

/**
 * Returns weather we have a valid email or not. We accept everything that follow x@x.
 *
 * @param email
 * @returns {boolean}
 */
function isValidEmail(email) {
    const splitted = email.split("@");
    if (splitted.length !== 2 || splitted[0].length === 0 || splitted[1].length === 0) {
        return false;
    }

    return true;
}

/**
 *Tests if a string contains a number
 *
 * @param {string} someString A string that could be a password
 */
function hasNumber(someString) {
    return /\d/.test(someString);
}

/**
 *Tests if a string contains an uppercase letter
 *
 * @param {string} someString A string that could be a password
 */
function hasUppercaseLetter(someString) {
    return /[A-Z]/.test(someString);
}

/**
 *Tests if a string contains a lowercase letter
 *
 * @param {string} someString A string that could be a password
 */
function hasLowercaseLetter(someString) {
    return /[a-z]/.test(someString);
}

/**
 *Tests if a string contains special characters
 *
 * @param {string} someString A string that could be a password
 */
function hasSpecialCharacter(someString) {
    return /[ !@#$%^&*§()_+\-=\[\]{};':"\\|,.<>\/?]/.test(someString);
}

/**
 * parses an URL and returns an object with all details separated
 *
 * @param {url} url The url to be parsed
 * @returns {SplittedUrl} Returns the split up url
 */
function parseUrl(url) {
    let authority;
    let splitted_authority;
    let full_domain;
    let base_url;
    let schema;
    let port = null;

    // According to RFC http://www.ietf.org/rfc/rfc3986.txt Appendix B
    const pattern = new RegExp("^(([^:/?#]+):)?(//([^/?#]*))?([^?#]*)(\\?([^#]*))?(#(.*))?");
    const matches = url.match(pattern);

    schema = matches[2];
    base_url = matches[2] + "://";

    if (typeof matches[4] !== "undefined") {
        base_url = base_url + matches[4];
        authority = matches[4].replace(/^(www\.)/, "");
        splitted_authority = authority.split(":");
    }

    if (typeof splitted_authority !== "undefined" && splitted_authority.length === 2) {
        port = splitted_authority[splitted_authority.length - 1];
    }
    if (typeof splitted_authority !== "undefined") {
        full_domain = splitted_authority[0];
    }

    return {
        scheme: schema,
        base_url: base_url,
        authority: authority, //remove leading www.
        full_domain: full_domain,
        top_domain: full_domain,
        port: port,
        path: matches[5],
        query: matches[7],
        fragment: matches[9],
    };
}

const service = {
    isValidEmail,
    hasNumber,
    hasUppercaseLetter,
    hasLowercaseLetter,
    hasSpecialCharacter,
    parseUrl,
};

export default service;
