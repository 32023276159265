import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import googleAuthenticatorService from "../../services/google-authenticator";
import Table from "../../components/table";


const GoogleAuthenticatorListTable = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [googleAuthenticators, setGoogleAuthenticators] = useState([]);

    React.useEffect( () => {
        loadGoogleAuthenticators();
    }, []);


    const loadGoogleAuthenticators = () => {
        googleAuthenticatorService.getGoogleAuthenticators().then(
            (googleAuthenticators) => {
                setGoogleAuthenticators(googleAuthenticators.map((googleAuthenticator) => [googleAuthenticator.id, googleAuthenticator.title]))
            },
            (response) => {
                console.log(response);
            }
        );
    }

    const onCreate = () => {
        history.push("/account/google-authenticator/create/");
    };
    const onDelete = (rowData) => {
        googleAuthenticatorService.deleteGoogleAuthenticator(rowData[0]).then(
            () => {
                loadGoogleAuthenticators();
            },
            (response) => {
                console.log(response);
            }
        );
    };

    const columns = [{ name: t("ID"), options: { display: "false" } }, { name: t("TITLE") }];

    const options = {
        filterType: "checkbox",
    };

    return <Table data={googleAuthenticators} columns={columns} options={options} onCreate={onCreate} onDelete={onDelete} />;
}

export default GoogleAuthenticatorListTable;
