import apiServer from "./api-server";

/**
 * Returns the details of a specific google authenticator
 *
 * @returns {Promise<object>}
 */
function getGoogleAuthenticators() {
    return new Promise((resolve, reject) => {
        return apiServer.readGoogleAuthenticators().then(
            (response) => {
                return resolve(response.data.google_authenticators);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_5f9098df-c348-4a04-8c2c-7970ece52389");
                reject(response);
            }
        );
    });
}

/**
 *
 * update a google authenticator
 *
 * @param {string} title
 *
 * @returns {Promise<unknown>}
 */
function createGoogleAuthenticator(title) {
    return new Promise((resolve, reject) => {
        return apiServer.createGoogleAuthenticator(title).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * deletes a google authenticator
 *
 * @param {string} googleAuthenticatorId
 *
 * @returns {Promise<unknown>}
 */
function deleteGoogleAuthenticator(googleAuthenticatorId) {
    return new Promise((resolve, reject) => {
        return apiServer.deleteGoogleAuthenticator(googleAuthenticatorId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 * Verifies a google authenticator
 *
 * @param {string} googleAuthenticatorId
 * @param {string} title
 * @param {string} googleAuthenticatorToken
 *
 * @returns {Promise<unknown>}
 */
function verifyGoogleAuthenticator(googleAuthenticatorId, title, googleAuthenticatorToken) {
    return new Promise((resolve, reject) => {
        return apiServer.verifyGoogleAuthenticator(googleAuthenticatorId, title, googleAuthenticatorToken).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

const service = {
    getGoogleAuthenticators,
    deleteGoogleAuthenticator,
    createGoogleAuthenticator,
    verifyGoogleAuthenticator,
};

export default service;
